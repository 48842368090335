window.$ = require('jquery');

// API URLs and Constants
const API_BASE_URL = "https://winefinder.fbwineapps.com/WhereToBuy/GetWinesAndAccountGroups";

/**
 * Updates the flavor dropdown with the provided wines.
 * @param {Array} wines - List of wines fetched from the API.
 */
function updateFlavorDropdown(wines) {
    const flavorDropdown = $('#ejgWhereToBuy #flavor');
    flavorDropdown.empty();

    wines.forEach(wine => {
        const option = $('<option>', {
            value: wine.value,
            text: wine.label
        });
        flavorDropdown.append(option);
    });

    // Select the first option and update the displayed text
    const firstOption = flavorDropdown.find('option:first');
    firstOption.prop('selected', true);
    $('#ejgWhereToBuy #flavorDropDownWrapper .selectedTxt').text(firstOption.text());
}

/**
 * Updates the account group radio buttons and selects the "In Stores" option by default.
 * @param {Array} accounts - List of account groups fetched from the API.
 */
function updateAccountGroups(accounts) {
    if (accounts.length >= 2) {
        let inStoresAccount = accounts.find(account => account.label.toLowerCase().includes("store"));
        let diningOutAccount = accounts.find(account => account.label.toLowerCase().includes("dining"));

        if (inStoresAccount) {
            $('#inStores').val(inStoresAccount.value).prop('checked', true);
            $('#inStoresLabel').text(inStoresAccount.label);
        }

        if (diningOutAccount) {
            $('#diningOut').val(diningOutAccount.value);
            $('#diningOutLabel').text(diningOutAccount.label);
        }
    }
}

/**
 * Fetches and updates the "Where To Buy" section.
 * @param {string} wineFinderId - The Wine Finder ID to fetch data for.
 */
function updateWTBSection(wineFinderId) {
    const apiUrl = `${API_BASE_URL}?WebSiteId=${wineFinderId}`;

    $.ajax({
        url: apiUrl,
        type: 'GET',
        dataType: 'jsonp',
        success: function (data) {
            if (data) {
                if (data.wines) {
                    updateFlavorDropdown(data.wines);
                }
                if (data.accounts) {
                    updateAccountGroups(data.accounts);
                }
            }
        },
        error: function () {
            console.error('Error fetching wine data');
        }
    });
}

/**
 * Handles the brand dropdown initialization and interaction.
 */
function handleBrandDropdown() {
    const brandDropdown = $('#brand');

    if (brandDropdown.length) {
        const flavorDropdown = $('#ejgWhereToBuy #flavor');
        flavorDropdown.empty().append(
            $('<option>', { value: '', text: 'Varietal *' })
        );
    }
}

/**
 * Sets up the brand dropdown change event listener.
 */
function setupBrandDropdownListener() {
    $(document).on('change', '#brand', function () {
        const selectedBrand = $(this).find('option:selected');
        const newWineFinderId = selectedBrand.data('winefinder-id');

        $('#ejgWhereToBuy').attr('data-winefinder-id', newWineFinderId);
        updateWTBSection(newWineFinderId);
    });
}

/**
 * Observes the DOM for the addition of the brand dropdown.
 */
function observeDOMForBrandDropdown() {
    const observer = new MutationObserver(() => {
        if ($('#brand').length) {
            observer.disconnect();
            handleBrandDropdown();
        }
    });

    observer.observe(document.body, { childList: true, subtree: true });
}

/**
 * Initializes the "Where To Buy" section.
 */
function initializeWTBSection() {
    setupBrandDropdownListener();
    observeDOMForBrandDropdown();
}

$(document).ready(initializeWTBSection);
